import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;

export const API_URL = {
    // LEAD_API: "http://localhost:4020/",
    // INVENTORY_API: "http://localhost:4021/",
    // DEALER_API: "http://localhost:4022/",
    GETWAY_API: process.env.REACT_APP_GETWAY_API,
    // GETWAY_API: "https://int-gcloud.gaadi.com/"
    // GETWAY_API: "http://192.168.72.36:8626/"
    // GETWAY_API: "http://localhost:8626/"
    TENANT: process.env.REACT_APP_CONFIG_FILE_NAME
}

export const CONF_VAL =conf;


