import React, { Component } from 'react';
import { Redirect, BrowserRouter as Router, Route } from 'react-router-dom';

import LoginForm from './login-form';

import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import logoscanner from './../assets/images/logo.png';
import { API_URL } from '../../../config/constant';
import LoginOtpValidation from '../component/LoginOtp';
import _ from 'lodash';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            key: 1,
            loading: false,
            redirect: 0,
            show: false,
            renderSection: 'login',
            lang_id: null,
            langOption: [],
            tenant: API_URL.TENANT,
            new_feature: true,
            showOtp: false,
            otpValue: "",
            captchaData: {},
            captureOtp: "",
            reloadCaptcha: 0

        };
    }

    componentWillMount = () => {
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({ langOption: langData });
                    this.setDefaultLanguage(null, langData)
                }
            });
        } else {
            this.setState({ langOption: localLangData });
            this.setDefaultLanguage(null, localLangData);
        }
    }

    componentDidMount() {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        }
    }

    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        /*if(role == "admin") {
            this.setState({redirect: 2 })
        }
        else if(role == "dealercentral") {
            this.setState({redirect: 1 })
        }*/
        this.setState({ redirect: 1 });
    }
    handleChangeEvent = event => {
        let fields = { ...this.state.fields };
        const errors = { ...this.state.errors };
        fields[event.target.name] = event.target.value;
        if (event.target.value !== '') {
            delete errors[event.target.name];
        } else {
            errors[event.target.name] = 'Please enter ' + event.target.name;
        }
        this.setState({ fields: fields, errors: errors });
    }

    submitAndValidateLoginForm = (event) => {
        event.preventDefault();
        const errors = { ...this.state.errors };
        if (this.validForm()) {
            const user_name = this.state.fields && this.state.fields.user_name ? this.state.fields.user_name : this.state.fields.email;
            const fields = { user_name, password: this.state.fields.password }
            MasterService.post('qc/user/login', fields)
                .then((response) => {
                    if (typeof response != 'undefined' && response.status == 200 && response.data.status == 200 && response.data) {
                        response = response.data;
                        let cityIds = (response.data && response.data.dealer_data && response.data.dealer_data[0] && response.data.dealer_data[0].city_ids)
                            ? response.data.dealer_data[0].city_ids
                            : [];
                        let localityApi = (cityIds.length > 0)
                            ? MasterService.post('core/commonservice/locality_list', { city: cityIds })
                            : Promise.resolve();
                        localityApi.then((locRes) => {
                            if (locRes && locRes.status == 200 && locRes.data.status == 200 && locRes.data.data) {
                                response.locality = locRes.data.data;
                            }
                            secureStorage.setItem('loginUserInfo', response);
                            // const {i18n } = this.props;

                            let lang_id = null;
                            if (response.data.user_data && response.data.user_data.lang)
                                lang_id = response.data.user_data.lang;
                            this.setDefaultLanguage(lang_id);
                            // let lang = (response.data.dealer_data[0].default_lang_id == 1) ? 'en': 'id';
                            // secureStorage.setItem('lang', lang);
                            // i18n.changeLanguage(lang)
                            this.props.updateStateIntoRedux('LOGIN_USER', response);
                            this.afterLoginSetState(response)
                        }).catch((error) => {
                            window.commonErrorToast(error.message);
                        });
                    } else {
                        if (typeof response != 'undefined' && response.data.message == 'INVALID_CREDENTIAL') {
                            errors['user_name'] = this.props.t('login.INVALID_CREDENTIAL');
                            this.setState({ errors: errors });
                            window.commonErrorToast(this.props.t('login.INVALID_CREDENTIAL'));

                        }
                        else
                            window.commonErrorToast(this.props.t('login.something_went_wrong'));
                    }
                }).catch(function (error) {
                    window.commonErrorToast(error.message);
                });
        }
    }

    setDefaultLanguage = (lang_id, localLangData) => {
        let lang = null;
        lang_id = lang_id || this.state.lang_id;
        localLangData = localLangData || secureStorage.getItem('langOption');
        if (lang_id)
            this.setState({ lang_id: lang_id })
        if (lang_id && localLangData && localLangData.length > 0)
            lang = (localLangData.filter(v => v.id == lang_id).map(v => v.iso_code))[0];
        if (lang) {
            secureStorage.setItem('lang', lang);
            if (this.props.i18n) this.props.i18n.changeLanguage(lang)
        }
    }

    getDealerLocality = (cityIds, callback) => {
        MasterService.get('core/commonservice/locality_list?city=' + cityIds[0]).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                callback(response.data.data);
            }
        })
            .catch((response) => {
            });
    }

    validForm = () => {
        let fields = { ...this.state.fields };
        const errors = { ...this.state.errors };
        const showFields = this.getLoginFields();
        let formIsValid = true;
        if (showFields.includes("user_name") && !fields["user_name"]) {
            errors['user_name'] = "Please enter username";
            formIsValid = false;
        }
        if (showFields.includes("email") && ! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields["email"])) {
            errors['email'] = "Please enter valid email";
            formIsValid = false;
        }
        if (showFields.includes("password") && !fields["password"]) {
            errors['password'] = "Please enter password";
            formIsValid = false;
        }
        if (!this.state.captchaData.captchaVal) {
            errors['captcha'] = "Please enter captcha";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    changeContent = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.props.updateStateIntoRedux('CHANGE_CONTENT', showingSection);
        this.setState({
            renderSection: showingSection
        })
    }
    switchLanguage = (event) => {
        let lang = event.currentTarget.dataset.key;
        const { i18n } = this.props;
        secureStorage.setItem('lang', lang);
        i18n.changeLanguage(lang)
    }

    getLoginFields = () => {
        // let current_lang = this.state.langOption && this.state.langOption[1] && this.state.langOption[1].name ? this.state.langOption[1].name  : 'en';//on basis of api response
        let current_lang = this.state.tenant;
        // switch (current_lang) {
        //     case "th":
        //         return ["email", "password", "captcha"]
        //     case "oto":
        //         return ["user_name", "password"]
        //     case "ph":
        //         return ["user_name", "password"]
        //     default:
        //         return ["user_name", "password"]
        // }
        return ["email", "password", "captcha"]

    };

    handleLoginWithCaptch = (event) => {
        event.preventDefault();
        let that = this;
        let res = this.validForm();
        if (res) {
            this.setState({ loading: true })
            let params = {
                captchaId: this.state.captchaData.captchaId,
                captchaVal: this.state.captchaData.captchaVal,
                username: this.state.fields.email,
                password: this.state.fields.password
            }
            MasterService.post('/qc/user/login-by-email', params)
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data.status === 200) {
                            that.handleSendOTP()
                        } else if (_.get(response, 'data.errors') === 'INVALID_CREDENTIAL') {
                            toast.error('INVALID_CREDENTIAL');
                            that.setState({ loading: false })
                            that.setState({ reloadCaptcha: that.state.reloadCaptcha + 1 });
                        } else if (_.get(response, 'data.errors.length') > 0) {
                            if (response.data.errors[0]) {
                                toast.error(response.data.errors[0]);
                            } else {
                                toast.error('Wrong Captcha')
                            }
                            that.setState({ loading: false })
                            that.setState({ reloadCaptcha: that.state.reloadCaptcha + 1 });
                        }
                    } else {
                        that.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    that.setState({ loading: false })
                });
        }
        // this.setState({ loading: false })
    }

    handleSendOTP = () => {
        const that = this;
        let params = {
            username: this.state.fields.email
        }
        MasterService.post('/qc/user/send_admin_otp', params)
            .then(function (response) {
                if (_.get(response, 'status') === 200) {
                    if (_.get(response, 'data.status') === 200 && _.get(response, 'data.data.value')) {
                        that.setState({ showOtp: true })
                        that.setState({ otpValue: response.data.data.value })
                        that.setState({ loading: false })
                        that.setState({
                            renderSection: 'otp'
                        })
                    } else if (_.get(response, 'data.errors')) {
                        toast.error(_.get(response, 'data.errors'));
                    }
                } else {
                    toast.error("Error")
                }
            })
            .catch(function (error) {
                toast.error(error);

            });
    }

    resendOTP = async () => {
        const that = this;
        let params = {
            username: this.state.fields.email
        }
        this.setState({ loading: true });
        MasterService.post('/qc/user/send_admin_otp', params)
            .then(function (response) {
                if (_.get(response, 'status') === 200) {
                    if (_.get(response, 'data.status') === 200 && _.get(response, 'data.data.value')) {
                        that.setState({ showOtp: true })
                        that.setState({ otpValue: response.data.data.value })
                        that.setState({
                            renderSection: 'otp'
                        })
                        that.setState({ loading: false });
                    } else if (_.get(response, 'data.error') && _.isArray(_.get(response, 'data.error')) && _.get(response, 'data.error').length > 0) {
                        toast.error('ERROR')
                        that.setState({ loading: false });
                    }
                } else {
                    toast.error('ERROR')
                    that.setState({ loading: false });
                }
            })
            .catch(function (error) {
                toast.error(error);
                that.setState({ loading: false });
            });
        
    }

    otpValidate = () => {
        let status = true;
        let errors = { ...this.state.errors }
        if (!this.state?.captureOtp) {
            errors['otp'] = "Please Enter Otp";
            this.setState({ errors: errors });
            status = false;
        } else {
            delete errors['otp'];
        }
        return status;
    }

    handleOTPSubmit = async (event) => {
        event.preventDefault();

        let res = this.otpValidate();
        if (res) {
            this.setState({ loading: true });
            let params = {
                username: this.state.fields.email,
                captchaId: this.state.captchaData.captchaId,
                captchaVal: this.state.captchaData.captchaVal,
                with_captcha: false,
                otp: this.state.otpValue,
                otp_value: this.state.captureOtp
            }
            let that = this;
            MasterService.post('/qc/user/validate_admin_otp', params)
                .then(function (response) {
                    if (
                        _.get(response, 'status') === 200 &&
                        _.get(response, 'data.status') === 200 &&
                        !_.isUndefined(response)
                    ) {
                        response = response.data;
                        let cityIds = _.get(response, 'data.dealer_data[0].city_ids')
                            ? response.data.dealer_data[0].city_ids
                            : [];
                        let localityApi = (cityIds.length > 0)
                            ? MasterService.post('core/commonservice/locality_list', { city: cityIds })
                            : Promise.resolve();
                        localityApi.then((locRes) => {
                            if (locRes && locRes.status == 200 && locRes.data.status == 200 && locRes.data.data) {
                                response.locality = locRes.data.data;
                            }
                            secureStorage.setItem('loginUserInfo', response);

                            let lang_id = null;
                            if (_.get(response, 'data.user_data.lang'))
                                lang_id = response.data.user_data.lang;
                            that.setDefaultLanguage(lang_id);
                            that.props.updateStateIntoRedux('LOGIN_USER', response);
                            that.afterLoginSetState(response)

                        }).catch((error) => {
                            window.commonErrorToast(error.message);
                        });
                    } else if (_.get(response, 'data.errors')) {
                        that.setState({ loading: false });
                        toast.error(_.get(response, 'data.errors'))
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    that.setState({ loading: false });
                });
        }
        this.setState({ loading: false });
    }


    captchaHandler = (data) => {
        const errors = { ...this.state.errors };
        delete errors['captcha'];
        this.setState({ errors: errors });
        this.setState({ captchaData: data });
    }

    otpChange = (event) => {
        let errors = { ...this.state.errors }
        delete errors['otp'];
        this.setState({ captureOtp: event.target.value });
        this.setState({ errors: errors });
    }

    render() {
        const showFields = this.getLoginFields();
        if (this.state.redirect == 1) {
            return (<Redirect to={'/quality-control'} />);
        }
        else if (this.state.redirect == 2) {
            return (<Redirect to={'/admin/dealer-list'} />);
        }
        const contentSection = this.state.renderSection;
        let dynamicComponent = '';
        if (contentSection === 'login' ) {
            dynamicComponent = <LoginForm errorMessage={this.state.errors} onClick={this.submitAndValidateLoginForm} onChange={this.handleChangeEvent} showFields={showFields} />;
        } //else if (contentSection === 'forgot') {
        //     dynamicComponent = <ForgotPassword />;
        // } else if (contentSection === 'contactus') {
        //     dynamicComponent = <ContactUs />;
        // } else {
        //     dynamicComponent = <JoinUs />;
        // }
        if (contentSection === 'login' ) {
            dynamicComponent = <LoginForm errorMessage={this.state.errors} onClick={this.handleLoginWithCaptch} onChange={this.handleChangeEvent} showFields={showFields} onKeyPressCaptcha={this.captchaHandler} reloadCaptcha={this.state.reloadCaptcha} />;
        }
        if (contentSection === 'otp') {
            dynamicComponent = <LoginOtpValidation onClick={this.handleOTPSubmit} onChange={this.otpChange} errorMessage={this.state.errors} resendOTP={this.resendOTP} />
        }
        const { t, i18n } = this.props;
        const selLang = secureStorage.getItem('lang') || 'en';
        const { langOption } = this.state;
        return (
            <div className={this.state.loading ? "container-fluid loading" : "container-fluid"} id="homePage">
                <div className="loginwrap">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-sm-offset-4 col-sm-offset-6">
                            <div className="mrg-b15 text-center"><img src={logoscanner} className="brand-logo" alt="Loan Box" /></div>
                            <div className="card">
                                <div className="card-body pd-30">
                                    {dynamicComponent}
                                </div>
                            </div>
                            <footer>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="language-change language-login">
                                            {langOption.length > 1 ?
                                                langOption.map((lang, index) =>
                                                    <button key={index} data-key={lang.iso_code} type='button' className={selLang == lang.iso_code ? 'btn btn-primary' : 'btn btn-default'} onClick={this.switchLanguage}>{lang.iso_code}</button>
                                                )
                                                : ''}


                                        </div>
                                    </div>
                                    {/* <div className="col-sm-8"><div className="copyright text-right">@ Copyright oto.com All Rights Reserved.</div></div> */}
                                </div>

                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Login));